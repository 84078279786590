import "./src/styles/global.css";
import React from "react";
import { CartProvider } from "./src/context/cartContext";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

initMercadoPago(process.env.GATSBY_MERCADO_PAGO_PUBLIC_KEY, {
  locale: "es-AR",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1, // Retries failed queries once
    },
    mutations: {
      onError: (error) => {
        console.error("Global mutation error:", error);
      },
    },
  },
});

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CartProvider>{element}</CartProvider>
    </QueryClientProvider>
  );
};
