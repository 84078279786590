exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-index-js": () => import("./../../../src/pages/catalogo/index.js" /* webpackChunkName: "component---src-pages-catalogo-index-js" */),
  "component---src-pages-checkout-background-dividers-js": () => import("./../../../src/pages/checkout/BackgroundDividers.js" /* webpackChunkName: "component---src-pages-checkout-background-dividers-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-comocomprar-js": () => import("./../../../src/pages/comocomprar.js" /* webpackChunkName: "component---src-pages-comocomprar-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-payment-failed-jsx": () => import("./../../../src/pages/payment-failed.jsx" /* webpackChunkName: "component---src-pages-payment-failed-jsx" */),
  "component---src-pages-payment-success-jsx": () => import("./../../../src/pages/payment-success.jsx" /* webpackChunkName: "component---src-pages-payment-success-jsx" */),
  "component---src-pages-terminos-js": () => import("./../../../src/pages/terminos.js" /* webpackChunkName: "component---src-pages-terminos-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */)
}

